import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import { Height } from '@mui/icons-material';

const ExperianceCard = ({ data }) => {
  return (

    <Card className='home_skill-container home_skill-img-card ' sx={{ color: '#fff', backgroundColor: '#2a376e !important', width: '70%', margin: '2%' }}>
      <Box>
        <Box sx={{ position: 'relative', height: '125px', width: '100%', backgroundColor: data?.bg }}>
          <Typography
            className='exp-org-title'
            sx={{ color: '#fff', fontSize: '1.6rem', fontWeight: 600, paddingTop: '3%' }}>
            {data?.organization}
          </Typography>
          <img style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%,0%)' }} height='100px' width='120px' src={data?.logo} alt="img" />

        </Box>

        <CardContent className='card-designation' sx={{ marginTop: '5%' ,display:'flex','@media (max-width:1200px)':{flexDirection:'column'}}}>
          <Box sx={{width:'50%','@media (max-width:1200px)':{width:'100%'}}}>
            <Typography sx={{ fontSize: '1.6rem',fontWeight:'bold' }} gutterBottom variant="h5" component="div">
              {data?.position}
            </Typography>
            <Typography sx={{ fontSize: '1.3rem',fontWeight:'bold' }} gutterBottom variant="h5" component="div">
              {data?.tenure}
            </Typography>
            {data?.skills && data?.skills?.map((item) => (<img height='70px' width='70px' style={{ margin: '5px' }} src={item?.src} />))}
          </Box>
          <Box sx={{textAlign:'left'}}>
            
              {data?.details && data?.details?.map((item)=>(<Typography sx={{ marginBottom: '2%',marginLeft:'5%', fontSize: '1.2rem' }} variant="body2" >{item}</Typography>))}
            
          </Box>

        </CardContent>
      </Box>
    </Card>
  );

}

export default ExperianceCard