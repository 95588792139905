import bootstrap from './Assets/bootstrap.svg'
import css from './Assets/css.svg'
import git from './Assets/git.svg'
import html from './Assets/html.svg'
import javascript from './Assets/javascript.svg'
import mui from './Assets/mui.svg'
import react from './Assets/react.svg'
import redux from './Assets/redux.svg'
import CreateJS from './Assets/CreateJS.svg'
import Docker from './Assets/Docker.svg'
import Express from './Assets/ExpressJS.svg'
import GitHub from './Assets/GitHub (2).svg'
import MongoDB from './Assets/MongoDB.svg'
import MySQL from './Assets/MySqL.svg'
import azure from './Assets/azure.svg'
import node from './Assets/node-js (1).svg'

export const skillData = [
    {name:'React JS',src:react},
    {name:'HTML 5',src:html},
    {name:'CSS 3',src:css},
    {name:'Javascript',src:javascript},
    {name:'Redux',src:redux},
    {name:'git',src:git},
    {name:'Git Hub',src:GitHub},
    {name:'Material UI',src:mui},
    {name:'Bootstrap',src:bootstrap},
    {name:'Node JS',src:node},
    {name:'Express JS',src:Express},
    {name:'Docker',src:Docker},
    {name:'MongoDB',src: MongoDB},
    {name:'MySql',src:MySQL},
    {name:'Create JS',src:CreateJS},
    {name:'Azure',src:azure},
    
]

export const jioSkills=[
    {name:'React JS',src:react},
    {name:'HTML 5',src:html},
    {name:'CSS 3',src:css},
    {name:'Javascript',src:javascript},
    {name:'Redux',src:redux},
    {name:'git',src:git},
    {name:'Material UI',src:mui},
    {name:'Create JS',src:CreateJS},
    {name:'Bootstrap',src:bootstrap},
]

export const cogniSkills = [
    {name:'React JS',src:react},
    {name:'HTML 5',src:html},
    {name:'CSS 3',src:css},
    {name:'Javascript',src:javascript},
    {name:'Redux',src:redux},
    {name:'git',src:git},
    {name:'Express JS',src:Express},
    {name:'Docker',src:Docker},
    {name:'MySql',src:MySQL},
    {name:'Node JS',src:node},
    {name:'Azure',src:azure},
   
]