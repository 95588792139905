import { Box, Button, TextField, Typography } from "@mui/material";
import { Header } from "../Header/Header";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { EmailJSResponseStatus } from "@emailjs/browser";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";

export const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMEssageError] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setError(!emailPattern.test(value));
  };

  const sendData = async () => {
    let data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_USER_ID,
      template_params: {
        email: email,
        name: name,
        message: message,
      },
    };
    try {
      await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = () => {
    if (message && email && name && !error) {
      sendData();
      return;
    }
    if (!message) {
      setMEssageError(true);
    }
    if (!name) {
      setNameError(true);
    }
    if (!email) {
      setError(true);
    }
  };

  const handleNameChange = (e) => {
    setName(e?.target?.value);
    if (e?.target?.value) {
      setNameError(false);
    }
  };
  const handleMessageChange = (e) => {
    setMessage(e?.target?.value);
    if (e?.target?.value) {
      setMEssageError(false);
    }
  };
  return (
    <Box
      id="contact"
      sx={{ backgroundColor: "#B57F50", height: "100%", width: "100%" }}
    >
      <Header navlink="2"/>
      <Box
        className="formContainer"
        sx={{
          padding: "1%",
          textAlign: "center",
          color: "white",
          overflow: "auto",
          backgroundColor: "#B57F50",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 900px)": {
              flexDirection: "column",
              gap: "30px",
              paddingBottom: "20px",
            },
          }}
        >
          <Box
            className=""
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              "@media (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            {/* <Typography>
                    Email me at <a href="mailto:tusharjadhav.acc@gmail.com" style={{ color: 'inherit', textDecoration: 'none' }}>jadhavtusharr1@gmail.com</a> or message here:
                </Typography> */}
            <TextField
              onChange={(e) => handleNameChange(e)}
              className="textField_styles"
              sx={{
                width: "500px",
                "& .Mui-error": { color: "black !important" },
              }}
              id="standard-password-input"
              label="Your Name"
              variant="standard"
              InputProps={{
                style: { color: "#4e2501" },
                disableUnderline: false,
                sx: {
                  "&:before": { borderBottomColor: "#4e2501" },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#4e2501",
                  },
                  "&:after": { borderBottomColor: "#4e2501" },
                },
              }}
              InputLabelProps={{
                style: { color: "#4e2501" },
              }}
              error={nameError}
              helperText={
                nameError ? "Please enter your name or organization name " : ""
              }
            />

            <TextField
              className="textField_styles"
              sx={{
                width: "500px",
                "& .Mui-error": { color: "black !important" },
              }}
              id="standard-password-input"
              type="email"
              label="E-mail"
              variant="standard"
              InputProps={{
                style: { color: "#4e2501" },
                disableUnderline: false,
                sx: {
                  "&:before": { borderBottomColor: "#4e2501" },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#4e2501",
                  },
                  "&:after": { borderBottomColor: "#4e2501" },
                },
              }}
              InputLabelProps={{
                style: { color: "#4e2501" },
              }}
              value={email}
              onChange={handleChange}
              error={error}
              helperText={error ? "Invalid email address" : ""}
            />

            <TextField
              onChange={(e) => handleMessageChange(e)}
              className="textField_styles"
              sx={{
                width: "500px",
                "& .Mui-error": { color: "black !important" },
              }}
              id="standard-password-input"
              label="Message"
              multiline
              rows={5}
              variant="standard"
              InputProps={{
                style: { color: "#4e2501" },
                disableUnderline: false,
                sx: {
                  "&:before": { borderBottomColor: "#4e2501" },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#4e2501",
                  },
                  "&:after": { borderBottomColor: "#4e2501" },
                },
              }}
              InputLabelProps={{
                style: { color: "#4e2501" },
              }}
              error={messageError}
              helperText={messageError ? "Please Enter Message" : ""}
            />
            <Button
              onClick={() => handleSubmit()}
              className="send_btn_styles"
              sx={{
                width: "480px",
                backgroundColor: "#784c25",
                "&:hover": { backgroundColor: "#784c25" },
                color: "#4e2501",
                fontWeight: "bold",
              }}
              variant="contained"
            >
              Send
            </Button>
          </Box>

          <Box
            sx={{
              "& .MuiTypography-root": { color: "#4e2501" },
              marginLeft: "5%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                gap: "20px",
              }}
            >
              <Box sx={{ display: "inline-flex", gap: "10px" }}>
                <AlternateEmailIcon
                  sx={{ margin: "auto 0", "& path": { color: "#4e2501" } }}
                />{" "}
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  jadhavtusharr1@gmail.com
                </Typography>
              </Box>
              <Box sx={{ display: "inline-flex", gap: "10px" }}>
                <CallIcon
                  sx={{ margin: "auto 0", "& path": { color: "#4e2501" } }}
                />{" "}
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  +91 7887994889
                </Typography>
              </Box>
              <Box sx={{ display: "inline-flex", gap: "10px" }}>
                <LocationOnIcon
                  sx={{ margin: "auto 0", "& path": { color: "#4e2501" } }}
                />{" "}
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Airoli, Navi Mumbai, Maharashtra, India, 400708
                </Typography>
              </Box>
            </Box>
            <Box>
            <a
                href="https://www.linkedin.com/in/tushar-jadhav-383817216/"
                target="_blank"
                rel="noopener noreferrer"
              >
              <LinkedInIcon
                sx={{
                  "& path": { color: "#4e2501" },
                  margin: "20px",
                  height: "50px",
                  width: "50px",
                }}
              />
              </a>
              <a
                href="https://github.com/jadhavtushar1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon
                  sx={{
                    "& path": { color: "#4e2501" },
                    margin: "20px",
                    height: "50px",
                    width: "50px",
                  }}
                />
              </a>
              {/* <InstagramIcon sx={{ '& path': { color: '#4e2501' }, margin: '20px', height: '50px', width: '50px' }} /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
