import { Box, Typography } from "@mui/material"
import './Home.css'
import { Header } from "../Header/Header"
import { skillData } from "../../Constants"


const Home = () => {

    return (
        <Box id='home' className='home-parent' sx={{backgroundColor:'#8ED081',height:'100%'}}>
            <Header navlink='0' />
            <Box  className="home-details">
                <Box sx={{ margin: '2%' }} >
                    <Typography sx={{ fontSize: '1.5rem',color:'#105703', }}> I'm Tushar Jadhav 👋</Typography>
                    <Typography sx={{ width: '70%', margin: '0 auto', fontSize: '1.2rem' ,color:'#105703',}}>
                        I am a frontend developer with two years of experience specializing in React, HTML5, CSS3, and JavaScript. My expertise includes developing mobile-first web applications and large-scale web applications. I am dedicated to delivering exceptional user experiences and ensuring high-quality, responsive design. My skill set encompasses a deep understanding of modern frontend technologies and best practices. I am committed to creating intuitive and engaging user interfaces.
                    </Typography>
                </Box>
                <Box className="home_skill-container home-skill-data" >
                    {skillData && skillData?.map((item, key) => (<img key={key} className='home_skill-img' height={100} width={100} title={item?.name} style={{ margin: '10px' }} src={item?.src} alt='skill_data' />
                    ))}
                </Box>
            </Box>

        </Box>

    )
}
export default Home