import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import './Header.css'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import { useNavigate } from 'react-router-dom';


const navItems = [
  {index:0, name: 'Home', navigate: '/', scrollto: 'home',color:'#8ED081',headerColor:'#105703',backGroundColor:'#6a9e64' },
  {index:1, name: 'Experience', navigate: '/Experiance', scrollto: 'experiance',color:'#4685f2',headerColor:'white',backGroundColor:'#2a376e' },
  {index:2, name: 'Contact', navigate: '/Contact', scrollto: 'contact' ,color:'#B57F50',headerColor:'#4e2501',backGroundColor:'#945b28'},
];

export const Header = (props) => {
  const navigate = useNavigate();
  const { window, navlink } = props;
  const [isOpen, setMobileOpen] = useState(false);
  const [download, setDownload] = useState(false)
  const [selected,setSelected] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavClick = (name) => {
    setSelected(true)
    setMobileOpen(false)
    const navTo = navItems.find((item) => item.name === name);

    if (navTo) {
     if (navTo?.scrollto) {
        try {
          navigate(navTo?.navigate)
        }
        catch (e) {
          console.log(e)
        }
      }
    }
  };
  const linkRef = React.useRef(null);

  React.useEffect(() => {
    if (download && linkRef.current) {
      linkRef.current.click();
    }
  }, [download]);


  return (
    <>
      <CssBaseline />
      <AppBar sx={{boxShadow:'none',position:'relative !important'}} className='heder-drawer' component="nav" >
        <Toolbar sx={{ backgroundColor: navItems[navlink]?.color,color:'#105703' }} id='head'>
          <a

            ref={linkRef}
            href={process.env.REACT_APP_RESUME_URL}
            style={{ display: 'none' }}
          >
            Go to Drive
          </a>
          <Box className='display-detect'>
            <Typography
              variant="h6"
              component="div"
              sx={{color:'#4d7546', flexGrow: 1, textAlign: 'initial', cursor: 'pointer', margin: 'auto', fontWeight:'bold',
                fontSize:'25px',
                color: navItems[navlink]?.headerColor,
                backgroundColor: 'transparent',
                margin: '10px',
                textTransform:'capitalize' }}
            >
              {'Tushar Jadhav'}
            </Typography>
            <Box >
              
              {navItems.map((item,index) => (
                <Button

                  onClick={() => handleNavClick(item.name)}
                  key={item.name}
                  sx={{
                    fontSize:'20px',
                    fontWeight:'bold',
                    color: navItems[navlink]?.headerColor,
                    backgroundColor: index==navlink? item?.backGroundColor:'transparent',
                    // backgroundColor:'red',
                    margin: '10px',
                    textTransform:'capitalize'
                  }}
                >
                  {item.name}
                </Button>
              ))}
              <Button
                sx={{
                  fontSize:'20px',
                  fontWeight:'bold',
                  backgroundColor: 'transparent',
                  margin: '10px',
                  textTransform:'capitalize',
                  color: navItems[navlink]?.headerColor
                }}
                onClick={()=>setDownload(true)}

              >
                 <RemoveRedEyeTwoToneIcon sx={{ marginRight: '10px', height: '30px', width: '30px' ,'path':{color:navItems[navlink]?.headerColor} }} />
                Resume
               
              </Button>
                {/* <FormControlLabel
                  control={<MaterialUISwitch  defaultChecked />}
                /> */}
             
            </Box>
          </Box>
          <Box sx={{ display: 'none', justifyContent: 'space-between', width: '100%' }} className='display-phone'>
            <Typography sx={{color:navItems[navlink]?.headerColor}}>Tushar Jadhav</Typography>
            <MenuIcon sx={{'& path':{color:navItems[navlink]?.headerColor}}} onClick={handleDrawerToggle} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box>
      </Box>
      {isOpen &&
        <Box sx={{ width: '100%', backgroundColor: navItems[navlink]?.color,zIndex:'2','& li':{color:navItems[navlink]?.headerColor}}} className='hamburger_menu'>
          <ul>
            <li onClick={() => handleNavClick('Home')} className='menu-list-item'>Home</li>
            <li onClick={() => handleNavClick('Experience')} className='menu-list-item'>Experiance</li>
            <li onClick={() => handleNavClick('Contact')} className='menu-list-item'>Contact</li>
          </ul>
        </Box>}
    </>
  );
};
