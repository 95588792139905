
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/HomePage/Home'
import { Experiance } from './Components/Experiance/Experiance';
import { Contact } from './Components/ContactPage/Contact';
import { Box, Button, IconButton } from '@mui/material';
import top_icon from './Assets/icons8-scroll-up-64.png'
import Projects from './Components/Projects/Projects';
function App() {
  const handleTop = () => {
    document?.getElementById('head')?.scrollIntoView({ behavior: 'smooth' });
  }
  return (
 <Router>
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/Experiance' element={<Experiance/>}/>
    <Route path='/Contact' element={<Contact/>}/>
  </Routes>
 </Router>

  );
}

export default App;
