import { Box } from "@mui/material"
import { Header } from "../Header/Header"
import ExperianceCard from "../ExperianceCard/ExperianceCard"
import jio_img from '../../Assets/jio_img.png'
import cognilogo from '../../Assets/cognizant_Logo.png'
import { jioSkills, cogniSkills } from "../../Constants"
import './Experiance.css'
export const Experiance = () => {
    const experianceData =
        [
            {
                logo: jio_img,
                organization: 'Jio Platforms Limited (JPL)',
                position: 'Front-End Developer',
                tenure: 'March 2023 - Present',
                details: [
                    '• Developed a highly user interface-focused web application designed for high-profile users, including VIPs and dignitaries of the country.',
                    '• Worked in a cross-functional team in implementing new stories and tasks.',
                    '• Received Spotlight Award for contribution in projects.',
                    '• Contributed to multiple projects under NamoApp, employing a mobile-first approach to ensure optimal user experience across devices.',
                    '• Enhanced several legacy codebases by converting them into React, significantly improving the performance of applications within the Jio Engage section of the MyJio app.',
                    '• Refactored code-base by creating reusable components and using MaterialUI.'
                ],
                bg: '#0f3cc9',
                skills: jioSkills
            },
            {
                logo: cognilogo,
                organization: 'Cognizant Technology Solutions',
                position: 'Full Stack Developer',
                tenure: 'July 2022 - Feb 2023',
                details: [
                    '• Worked as a fullstack developer on a CRM project, handling both frontend and backend tasks.',
                    '• Implemented visually appealing user interfaces, enhancing the overall user experience.',
                    '• Adopted a mobile-first design strategy to ensure optimal usability across devices.',
                    '• Utilized React.js, React-Redux, and Bootstrap for building responsive and interactive frontend components.',
                    '• Used Express and SQL for server-side development and database management.',
                ],
                bg: '#2f78c4',
                skills: cogniSkills
            }
        ]
    return (
        <Box className='exp-parent' sx={{ backgroundColor: '#4685f2', height: '100%' }}>
            <Header navlink='1'/>
            <Box sx={{ backgroundColor: '#4685f2' }} id='experiance' className="exp-conainer exp-conteiner-data">
                <Box className='skill-conteiner-cards exp-skill-data-layout'>
                    {experianceData && experianceData?.map((item, key) => (<ExperianceCard key={key} data={item} />))}
                </Box>
            </Box>
        </Box>
    )
}